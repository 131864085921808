import * as React from "react"
import Layout from "../components/layout"
import JobPostsFilter from "../components/jobPostsFilter"
import Seo from "../components/seo"
import JobsNav from "../components/jobsNav"
import JobsNavFohm from "../components/jobsNavFohm"

/*

Categories:

chefs = 2
front-of-house = 9
sales-marketing-events = 11
operations = 13
international = 4 


*/

const FrontOfHouse = () => (
  <Layout
    jobPage={true}
    splashPage={false}
    pageTitle="VACANCIES - FRONT OF HOUSE"
  >
    <Seo title="Vacancies front of house" />
    <JobsNav link={"front"} />
    <JobsNavFohm />
    <JobPostsFilter category={9} />
  </Layout>
)

export default FrontOfHouse
